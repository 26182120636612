<template>
  <div>
    <search-hall-fee-detail @submit="submitSearch" ref="searchForm" />

    <div class="custom-table-operation-buttons">
      <a-space>
        <a-button type="primary" @click="download">下载</a-button>
      </a-space>
    </div>

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      @change="sortChange"
    />

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import SearchHallFeeDetail from '@/views/fee_data/hall_fee_details/Search'
import { findHallFeeDetails, findHallFeeDownloadData } from '@/api/fee_detail'
import { downLoadExcel } from '@/utils/downLoadExcel'
import { formatBigNumber, formatCurrency } from '@/utils/filter'

export default {
  name: 'HallFeeDetails',
  components: {
    Pagination,
    SearchHallFeeDetail
  },
  data() {
    return {
      query: { effective: 'true' },
      data: [],
      loading: true,
      previewVisible: false,
      pagination: {
        total_count: 0
      },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '订单号',
          dataIndex: 'order_no',
          width: 220,
          fixed: 'left'
        },
        {
          title: '订单类型',
          width: 150,
          dataIndex: 'order_type'
        },
        {
          title: '订单状态',
          width: 150,
          dataIndex: 'order_status'
        },
        {
          title: '殡仪馆',
          width: 90,
          dataIndex: 'funeral_home_name'
        },
        {
          title: '所选厅房',
          width: 120,
          dataIndex: 'hall_name'
        },
        {
          title: '厅房价格',
          width: 150,
          dataIndex: 'hall_price'
        },
        {
          title: '停棺时长',
          width: 120,
          dataIndex: 'stop_coffins_time'
        },
        {
          title: '厅房金额(元)',
          width: 120,
          dataIndex: 'hall_fee'
        },
        {
          title: '订单来源',
          width: 120,
          dataIndex: 'order_source'
        },
        {
          title: '申请人',
          width: 120,
          dataIndex: 'apply_name'
        },
        {
          title: '联系电话',
          width: 150,
          dataIndex: 'phone_number'
        },
        {
          title: '负责人',
          width: 120,
          dataIndex: 'person_name'
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          width: 180
        }
      ]
    }
  },
  methods: {
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      // 重置排序
      this.sort = this.$options.data().sort
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    download() {
      findHallFeeDownloadData(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          const header = `订单号,订单类型,订单状态,殡仪馆,所选厅房,厅房价格,停棺时长,厅房金额（元）,订单来源,申请人,联系电话,负责人,创建时间\n`
          downLoadExcel(header, res.data, '厅房费用明细')
        }
      })
    },

    fetchData() {
      this.loading = true
      findHallFeeDetails(Object.assign({}, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    }
  }
}
</script>
